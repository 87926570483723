
/* CoachBookingPage.css - Comprehensive and complete styling adapted for react-calendar */
.booking-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  padding: 10% 15%; /* Adjust the padding as needed to fit your design */
}
.booking-heading {
  font-family: 'Arial', sans-serif; /* Font family for the calendar */
  font-size: 24px;
  margin-top: -50px; /* Moves the heading up; adjust the value as needed */
  margin-left: -350px;
}
.booking-container {
  display: flex;
  flex-direction: column;
  width: 75%; /* Adjust the width as needed */
  height: 500px; /* Adjust the height as needed */
  margin: 0 auto; /* Center the container */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adding shadow for depth */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Hides overflow */
}
.calendar-and-timeslots-container {
  flex: 95%; /* Calendar and timeslots take 85% of the booking-container height */
  display: flex;
  margin: 15px; /* Padding inside this container */
  max-height: calc(100% - 110px);
}
.calendar {
  flex: 70%; /* Calendar takes 70% of the width */
  border: none;
  background: white;
  margin: 15px;
}
.time-slots {
  flex: 0 0 30%; /* Taking 30% of the container width */
  max-height: 100%; /* Ensuring it doesn't exceed the container height */
  overflow-y: auto; /* Adding scroll only vertically */
  margin-left: 10px; /* Adding some space between calendar and time slots */
  padding-top: 70px; /* Start the time slots lower */
  /* Rest of your styles */
}
.time-slot {
  display: block; /* Make time slots vertical */
  width: 90%; /* Full width of the container */
  margin: 0.5rem 0; /* Spacing between time slots */
  background: #ffffff; /* Default background color for time slots */
  border: 1px solid #e5e6e7; /* Border for time slots */
  padding: .5rem 1rem; /* Padding inside time slots */
  cursor: pointer; /* Cursor to indicate clickable */
  font-size: 0.9rem; /* Font size for time slots */
  border-radius: 10px;
}
.time-slot:hover {
  background-color: #e7f0fd; /* Gray background for available dates */
  color: #1d60f1; /* White text color on hover */
  font-weight: bold;
  /* Add any other hover styles if needed */
}
.time-slot.selected {
  background-color: #1890ff; /* Background color for selected date/time */
  color: white; /* Text color for selected date/time */
  border-color: #1890ff; /* Border color for selected date/time */
  font-weight: bold;
}
.button-container {
  flex: 15%; /* Button container takes 15% of the booking-container height */
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  padding: 10px; /* Padding around buttons */
}
.selected-slot-summary {
  flex-grow: 1; /* Allow summary to take available space */
  text-align: left;
  margin-top: 6px;
  margin-left: 20px;
  margin-right: 20px; /* Ensure space between summary and buttons */
  white-space: wrap; /* Prevent wrapping inside summary */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  max-width: calc(100% - 250px); /* Adjust max-width to prevent overflow into buttons */
  font-family: 'Arial', sans-serif; /* Font family for the calendar */
  font-size: 15px;
  color: #0084ff;
}
.back-btn,
.confirm-booking-btn {
  padding: 10px 20px; /* Padding inside buttons */
  background-color: #ececec; /* Background color for buttons */
  color: black; /* Text color for buttons */
  border: none; /* No border for buttons */
  cursor: pointer; /* Cursor to indicate clickable */
  font-size: 1rem; /* Font size for buttons */
  margin: 0 15px; /* Margin between buttons */
  width: 120px; /* Width for buttons */
  height: 40px; /* Height for buttons */
  align-self: center; /* Align buttons to center vertically */
  border-radius: 4px;
  transition: background-color 0.3s;
}
.confirm-booking-btn:enabled {
  background-image: linear-gradient(180deg, #F5973A 0%, #FFD23E 100%); /* Background color when button is disabled */
  color: #04003F; /* Text color when button is disabled */
}

/* Hover effect for 'Back' button */
.back-btn:hover {
  background-color: #e0e0e0; /* Darker shade of grey on hover */
}

/* Hover effect for 'Next' button when it is enabled */
.confirm-booking-btn:enabled:hover {
  background-image: linear-gradient(180deg, #ff870f 0%, #ffd102 100%); /* Slightly darker blue on hover */
}

/* Scrollbar styles for time slots container */
.time-slots::-webkit-scrollbar {
  width: 8px; /* Width of scrollbar */
}
.time-slots::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of scrollbar track */
}
.time-slots::-webkit-scrollbar-thumb {
  background: #888; /* Background of scrollbar thumb */
  border-radius: 10px; /* Border radius of scrollbar thumb */
}
.time-slots::-webkit-scrollbar-thumb:hover {
  background: #555; /* Background of scrollbar thumb on hover */
}
.react-calendar {
  width: 320px; /* Calendar width to fill the space */
  height: 320px; /* Calendar height to fit content */
  border: none;
  font-family: 'Arial', sans-serif; /* Font family for the calendar */
  font-size: 15px; 
}
/* Adjust navigation container to use flexbox and justify content to space-between */
.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.react-calendar__navigation button:first-child,
.react-calendar__navigation button:last-child {
  display: none;
}
/* Move both arrows to the right side */
.react-calendar__navigation button:nth-child(2) {
  order: 2; /* move the left arrow to the right side */
}
.react-calendar__navigation button:nth-child(4) {
  order: 3; /* ensure the right arrow stays on the right side */
}
/* Optional: Remove the space where the double arrows used to be */
.react-calendar__navigation button:first-child,
.react-calendar__navigation button:last-child {
  margin: 0;
  flex-grow: 1;
  text-align: right;
}
/* Navigation Buttons (arrows) */
.react-calendar__navigation button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 1.5rem;
  color: #000000; /* Color for navigation buttons */  
}

/* Month and Year Label */
.react-calendar__navigation__label {
  font-size: 1.2em;
  text-align: left;
  pointer-events: none;
  
}
/* Weekday Labels (Sun, Mon, Tue, etc.) */
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none; /* Removes underline from abbr element */
  font-weight: bold;
  color: #333;
}
/* Day Cells */
.react-calendar__month-view__days__day {
  padding: .9em; /* Padding inside day cells */
  text-align: center;
  
}
/* Day cells */
.react-calendar__tile {
  padding: 15px; /* Spacing inside day cells */
  font-size: 15px; 
  color: #000000; 
}
.react-calendar__tile.available-date {
  background-color: #e7f0fd; /* Gray background for available dates */
  color: #1d60f1; /* Black text for readability transparent */ 
  border-radius: 50%;
  font-weight: bold;
}
/* Ensuring available dates show a pointer cursor */
.available-date:hover {
  cursor: pointer;
}
.unavailable-date {
  pointer-events: none;
}
/* Active Day Style (selected day) */
.react-calendar__tile--active {
  background-color: #0073ff !important; /* Background color for the active day */
  color: white !important; /* Text color for the active day */
  border-radius: 50% !important;
}
/* Today Style */
.react-calendar__tile--now {
  position: relative; /* Position relative to place the pseudo-element */
  background-color: transparent;
  color: inherit;
}
.react-calendar__tile--now::after {
  content: ''; /* Necessary for pseudo-elements */
  display: block; /* Allows positioning */
  margin-top: 4px; /* Space between the date and the dot */
  height: 5px; /* Height of the dot */
  width: 5px; /* Width of the dot */
  background-color: #333; /* Dot color */
  border-radius: 50%; /* Makes the dot round */
  position: absolute; /* Positions the dot relative to the date cell */
  left: 50%; /* Centers the dot horizontally */
  transform: translateX(-50%); /* Aligns center of the dot with center of the cell */
  bottom: 8px; /* Position from the bottom of the cell */
}
.react-calendar__tile--now.available-date:hover {
  background-color: #e2e2e2; /* Ensure gray background color on hover */
}
.time-zone-display {
  text-align: left;
  margin-top: 60px; /* Adjust as needed */
  font-family: 'Arial', sans-serif; /* Font family for the calendar */
  font-size: 15px;
}
.select-date-message {
  font-family: 'Arial', sans-serif; /* Font family for the calendar */
  font-size: 15px;
  margin-top: 100px;
  margin-right:10px; 
  
  
}
/* Media queries for responsive adjustments */
@media screen and (max-width: 768px) {

  .booking-heading {
    font-size: 20px; /* Slightly smaller font size for smaller screens */
    margin-top: 10px; /* Positive margin to push it below the header */
    margin-left: 0; /* Resetting left margin to ensure it is centered */
  }
}
@media screen and (max-width: 480px) {
  .booking-heading {
    font-size: 18px; /* Even smaller font size for small screens */
    margin-top: 30px; /* Adjusted to ensure it's below any top UI elements */
    margin-left: 0;
  }
}
